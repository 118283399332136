.hero.is-primary.is-bold {
  background-image: linear-gradient(
      141deg,
      rgba(0, 158, 108, 0.6) 0,
      rgba(0, 209, 178, 0.8) 71%,
      rgba(0, 231, 235, 0.9) 100%
    ),
    url('ross-sneddon-NAPBJyy5Eqg-unsplash.jpg');
  background-size: cover;
  background-position: 0% 50%;
}
